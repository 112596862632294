$('body:not(.main-body)').fadeIn(500);
$('.link').click(function (event) {
	event.preventDefault();
	newLocation = $('.link a').attr("href");
	$('body:not(.main-body)').fadeOut(2000, newpage);
});

function newpage() {
	window.location = newLocation;
}

$(function () {

	var topParallaxSceneBg = document.querySelector('.top-parallax__scene-bg');
	if (topParallaxSceneBg) {
		new Parallax(topParallaxSceneBg);
	}

	var topParallaxSceneTitle = document.querySelector('.top-parallax__scene-title');
	if (topParallaxSceneTitle) {
		new Parallax(topParallaxSceneTitle);
	}

	// Submenu
	$('.mb-arrow-down').on('click', function (e) {
		e.preventDefault();

		var self = $(this);

		if (self.hasClass('show')) {
			self.removeClass('show');
			self.next().removeClass('nav-horizontal__submenu__visible');
		} else {
			self.parent().removeClass('show');
			self.toggleClass('show');
			self.next().toggleClass('nav-horizontal__submenu__visible');
		}
	});

	function hideSubmenu() {
		$('.mb-arrow-down').removeClass('show');
		$('.nav-horizontal__submenu').removeClass('nav-horizontal__submenu__visible');
	}

	$(window).on('resize', function () {
		hideSubmenu();
	});

	$('.menu-icon').on('click', function () {
		hideSubmenu();
	});

	// Active menu link
	var location = window.location.href;
	var cur_url = '/' + location.split('/').pop();

	$('.nav-horizontal a').each(function () {
		var link = $(this).attr('href');

		if (cur_url == link) {
			$(this).addClass('active');
		}
	});

});